import {
	GetEventListResponse,
	IEvent,
	isSuccess,
	ServiceApi,
	SettingItemResponse,
} from '@/services';
import { mapV2 } from '@/utils';
import { PayloadAction } from '@reduxjs/toolkit';

import { formatEventList, formatSetting } from './LuckyDraw.helper';
import { LuckyDrawActions, LuckyDrawSelectors } from './LuckyDraw.redux';
import {
	IDialSetting,
	IPayloadCreateEventRequest,
	IPayloadGetSettingByEventIdRequest,
	IPayloadUpdateDialSetting,
	IPayloadUpdateEventRequest,
	IPayloadUpdateResultRequest,
	ResourceTypeEnum,
} from './LuckyDraw.type';

import { ApiResponse } from 'apisauce';
import { put, select } from 'redux-saga/effects';

export function* getEventList() {
	try {
		const res: ApiResponse<GetEventListResponse> =
			yield ServiceApi.getEventList();
		if (isSuccess(res) && res?.data) {
			const { eventJson, eventList } = formatEventList(res?.data?.data);
			yield put(
				LuckyDrawActions.getEventListSuccess({
					eventJson,
					eventList,
				})
			);
			if (eventList?.[0]?.id) {
				yield put(
					LuckyDrawActions.getSettingByEventIdRequest({
						eventId: eventList?.[0]?.id,
					})
				);
			}
		} else {
			yield put(LuckyDrawActions.getEventListFailure());
		}
	} catch (e) {
		yield put(LuckyDrawActions.getEventListFailure());
	}
}

export function* createEvent(
	action: PayloadAction<IPayloadCreateEventRequest>
) {
	try {
		const { data, callback } = action.payload;
		const res: ApiResponse<IEvent> = yield ServiceApi.createEvent(data);
		if (isSuccess(res)) {
			yield put(LuckyDrawActions.createEventSuccess());
			yield put(LuckyDrawActions.getEventListRequest());
			if (res?.data?.id) {
				yield put(
					LuckyDrawActions.getSettingByEventIdRequest({
						eventId: res?.data?.id,
					})
				);
			}

			callback?.();
		} else {
			yield put(LuckyDrawActions.createEventFailure());
			yield put(LuckyDrawActions.resetFirstEvent());
		}
	} catch (e) {
		yield put(LuckyDrawActions.createEventFailure());
		yield put(LuckyDrawActions.resetFirstEvent());
	}
}

export function* updateEvent(
	action: PayloadAction<IPayloadUpdateEventRequest>
) {
	try {
		const { data, callback } = action.payload;
		const res: ApiResponse<any> = yield ServiceApi.updateGuestEvent(
			data?.id,
			{
				name: data?.name,
				startDate: data?.startDate,
			}
		);
		if (isSuccess(res)) {
			yield put(LuckyDrawActions.updateEventSuccess());
			yield put(LuckyDrawActions.getEventListRequest());
			callback?.();
		} else {
			yield put(LuckyDrawActions.updateEventFailure());
		}
	} catch (e) {
		yield put(LuckyDrawActions.updateEventFailure());
	}
}

export function* updateDialSetting(
	action: PayloadAction<IPayloadUpdateDialSetting>
) {
	try {
		const { eventId, setting, callback } = action.payload;
		if (!eventId) {
			yield put(LuckyDrawActions.updateDialSettingSuccess());
			callback?.();
			return;
		}
		const res: ApiResponse<any> = yield ServiceApi.updateSetting(
			setting?.id,
			{
				drawCount: setting?.drawCount,
				drawDuration: setting?.drawDuration,
				prizeNumber: setting?.prizeNumber,
				typeDraw: setting?.drawType,
				resourceType: setting?.resourceType,
				minNumber: setting?.minNumber,
				maxNumber: setting?.maxNumber,
				prizes: mapV2(
					setting?.prizeList?.slice(0, Number(setting?.prizeNumber)),
					(item, index) => ({
						eventId,
						name: item?.name,
						resultCount: item?.resultCount,
						settingId: setting?.id,
						prizeIndex: index,
					})
				),
				participants:
					setting?.resourceType === ResourceTypeEnum.RANGE_NUM
						? []
						: mapV2(setting?.participants, (item) => ({
								...item,
								eventId,
						  })),
			}
		);
		if (isSuccess(res)) {
			yield put(LuckyDrawActions.updateDialSettingSuccess());
			yield put(LuckyDrawActions.getSettingByEventIdRequest({ eventId }));
			callback?.();
		} else {
			yield put(LuckyDrawActions.updateDialSettingFailure());
		}
	} catch (e) {
		yield put(LuckyDrawActions.updateDialSettingFailure());
	}
}

export function* getSettingByEventId(
	action: PayloadAction<IPayloadGetSettingByEventIdRequest>
) {
	try {
		const { eventId, callback } = action.payload;
		const res: ApiResponse<SettingItemResponse> =
			yield ServiceApi.getSettingByEventId(eventId);
		if (isSuccess(res) && res?.data) {
			const setting = formatSetting(res?.data);
			yield put(
				LuckyDrawActions.getSettingByEventIdSuccess({
					eventId,
					setting,
				})
			);
			callback?.();
		} else {
			yield put(LuckyDrawActions.getSettingByEventIdFailure());
		}
	} catch (e) {
		yield put(LuckyDrawActions.getSettingByEventIdFailure());
	}
}

export function* updateResult(
	action: PayloadAction<IPayloadUpdateResultRequest>
) {
	try {
		const { eventId, callback, prizeIndex, results } = action.payload;

		if (!eventId) {
			yield put(LuckyDrawActions.updateDialSettingSuccess());
			callback?.();
			return;
		}

		const setting: IDialSetting = yield select(
			LuckyDrawSelectors.selectSelectedDialSetting
		);

		const res: ApiResponse<any> = yield ServiceApi.createResult({
			prizeId: setting?.prizeList?.[prizeIndex]?.id,
			code: results[0]?.code,
			name: results[0]?.name,
		});
		if (isSuccess(res) && res?.data) {
			yield put(LuckyDrawActions.updateResultSuccess());
			callback?.();
		} else {
			yield put(LuckyDrawActions.updateResultFailure());
		}
	} catch (e) {
		yield put(LuckyDrawActions.updateResultFailure());
	}
}

export function* resetResultList() {
	try {
		const eventId: number = yield select(
			LuckyDrawSelectors.selectSelectedEventId
		);
		if (!eventId) {
			yield put(LuckyDrawActions.resetResultListSuccess());
			return;
		}

		const res: ApiResponse<any> = yield ServiceApi.resetPrizeResults(
			eventId
		);

		if (isSuccess(res)) {
			yield put(LuckyDrawActions.resetResultListSuccess());
		} else {
			yield put(LuckyDrawActions.resetResultListFailure());
		}
	} catch (e) {
		yield put(LuckyDrawActions.resetResultListFailure());
	}
}
