import { formatEventDetail, formatSetting } from './LuckyDraw.helper';

export enum ResourceTypeEnum {
	RANGE_NUM = 'RANGE_NUM',
	POPULAR = 'POPULAR',
	LIST_INPUT = 'LIST_INPUT',
	LIST_EXPORT = 'LIST_EXPORT',
}

export enum DrawTypeEnum {
	AUTO,
	MANUAL,
}

export interface IEventItem {
	id: number;
}

export interface IResult {
	code: string;
	name: string;
	participantId?: number;
}

export type IEventDetail = ReturnType<typeof formatEventDetail>;

export interface IEventJson {
	[eventId: number]: IEventDetail;
}

export type IDialSetting = ReturnType<typeof formatSetting> & {
	prizeList: Array<{
		id: number;
		name: string;

		resultCount: number;
		results: IResult[];
	}>;
};

export interface IDialSettingJson {
	[key: number]: IDialSetting;
}

export type LuckyDrawSettingStore =
	| {
			type: ResourceTypeEnum.RANGE_NUM;
			min: number;
			max: number;
	  }
	| {
			type: ResourceTypeEnum.LIST_EXPORT;
			data: any[];
	  }
	| {
			type: ResourceTypeEnum.LIST_INPUT;
			data: any[];
	  };

export interface IPayloadUpdateDialSetting {
	eventId: number;
	setting: IDialSetting;
	callback?(): void;
}

export interface IPayloadGetEventListSuccess {
	eventList: IEventItem[];
	eventJson: IEventJson;
}

export interface IPayloadCreateEventRequest {
	data: {
		name: string;
		startDate: string;
	};
	callback?(): void;
}

export interface IPayloadUpdateEventRequest {
	data: {
		id: number;
		name: string;
		startDate: string;
	};
	callback?(): void;
}

export interface IPayloadGetSettingByEventIdRequest {
	eventId: number;
	callback?(): void;
}

export interface IPayloadGetSettingByEventIdSuccess {
	eventId: number;
	setting: IDialSetting;
}

export interface IPayloadUpdateResultRequest {
	eventId: number;
	prizeIndex: number;
	results: Array<{
		name: string;
		code: string;
	}>;
	callback?(): void;
}

export interface IPayloadUpdatePrizeListRequest {
	eventId: number;
	prizeList: IDialSetting['prizeList'];
	callback?(): void;
}

export interface NewEventValuesType {
	name: string;
	startDate: string;
	organizer: string;
	eventType: string;
}
