export const Images = {
	bg: require('./bg_lucky.png'),
	one: require('./one.png'),
	two: require('./two.png'),
	three: require('./three.png'),
	logo_google: require('./logo_google.png'),
	logo_facebook: require('./logo_facebook.png'),
	logo_facebook_color: require('./logo_facebook_color.png'),
	logo_google_color: require('./logo_google_color.png'),
	crown: require('./crown.png'),
	logo_momo: require('./logo_momo.png'),
	qr_code: require('./qr_code.jpg'),
	instruct_1: require('./instruct_1.png'),
	instruct_2: require('./instruct_2.png'),
	instruction: {
		setting_dial: require('./instruction/setting_dial.png'),
		setting_theme: require('./instruction/setting_theme.png'),
		setting_package: require('./instruct_3.png'),
	},
};
