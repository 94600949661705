import { ReactElement, useCallback, useState } from 'react';

export const useMultiSteps = (steps: ReactElement[]) => {
	const [currentStepIndex, setCurrentStepIndex] = useState(0);

	const onNext = useCallback(() => {
		setCurrentStepIndex((prevStep) => {
			if (prevStep >= steps.length - 1) return prevStep;
			return prevStep + 1;
		});
	}, [currentStepIndex]);

	const onBack = useCallback(() => {
		setCurrentStepIndex((prevStep) => {
			if (prevStep <= 0) return prevStep;
			return prevStep - 1;
		});
	}, [currentStepIndex]);

	return {
		currentStepIndex,
		step: steps[currentStepIndex],
		isFirstStep: currentStepIndex === 0,
		isLastStep: currentStepIndex === steps.length - 1,
		onNext,
		onBack,
	};
};
