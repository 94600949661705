import { GeneralActions, GeneralSelectors, ModalType } from '@/store/General';
import { insertIf } from '@/utils';

import { AuthSelector } from '../Auth';
import { LuckyDrawActions } from '../LuckyDraw';
import { ProfileActions } from '../Profile';

import { all, put, select } from 'redux-saga/effects';

export function* startup() {
	/** START UP */
	const logged: boolean = yield select(AuthSelector.selectLogged);
	const isVisited: boolean = yield select(GeneralSelectors.selectIsVisited);

	yield all([
		put(ProfileActions.getProfileRequest()),
		...insertIf(!logged, put(LuckyDrawActions.setSelectedEvent(0))),
	]);
	// you can call something needs

	yield all([
		...insertIf(!isVisited, put(GeneralActions.setVisited())),
		...insertIf(
			!isVisited,
			put(GeneralActions.openModalByType(ModalType.INSTRUCT))
		),
	]);
}
