import { useCallback, useEffect } from 'react';

export const useOnEsc = (cb: () => void) => {
	const onEsc = useCallback(
		(evt: any) => {
			if (evt?.key === 'Escape') cb?.();
		},
		[cb]
	);

	useEffect(() => {
		document.addEventListener('keydown', onEsc, false);
		return () => document.removeEventListener('keydown', onEsc, false);
	}, [onEsc]);
};
