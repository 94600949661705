import { storage } from '@/store/Store.persist';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

import { persistReducer } from 'redux-persist';

export enum ModalType {
	NONE,
	SIGN_IN,
	SIGN_UP,
	FORGOT,
	ALERT,
	GUIDE,
	CONTACT,
	LIST_RESULT,
	LIST_EVENT,
	CREATE_EVENT,
	UPDATE_EVENT,
	SETTING,
	THEME,
	LIST_OPTION,
	CONTACT_HELP,
	INSTRUCT,
	SEND_EMAIL,
	FIRST_TIME_SETUP,
}

const initialState: IGeneralState = {
	modalTypes: [],
	isVisited: false,
};

type IGeneralState = {
	modalTypes: ModalType[];
	isVisited: boolean;
};

export const GeneralSelectors = {
	selectModalTypes: (state: RootState) => state.general.modalTypes,
	getModalZIndex: (state: RootState, modalType: ModalType) => {
		const modalTypes = state.general.modalTypes;
		const modalIndex = modalTypes.indexOf(modalType);
		return modalIndex >= 0 ? 199 + modalIndex * 100 : 199;
	},
	selectIsVisited: (state: RootState) => state.general.isVisited,
};

const generalSlice = createSlice({
	initialState,
	name: 'General',
	reducers: {
		openModalByType: (state, action: PayloadAction<ModalType>) => {
			if (!state.modalTypes.includes(action.payload)) {
				state.modalTypes.push(action.payload);
			}
		},
		closeModalByType: (state, action: PayloadAction<ModalType>) => {
			const modalType = action.payload;
			if (state.modalTypes.includes(modalType)) {
				// Remove modalType from current position and add to end
				state.modalTypes = [
					...state.modalTypes.filter((type) => type !== modalType),
				];
			} else {
				// If modalType not found, just filter it out
				state.modalTypes = state.modalTypes.filter(
					(type: ModalType) => type !== modalType
				);
			}
		},
		resetModalTypes: (state) => {
			state.modalTypes = [];
		},
		setVisited: (state) => {
			state.isVisited = true;
		},
		resetVisit: (state) => {
			state.isVisited = false;
		},
	},
});

export const GeneralActions = generalSlice.actions;
export const GENERAL_PERSIST_KEY = 'generalPersist';
export const GeneralReducer = persistReducer(
	{
		storage,
		key: GENERAL_PERSIST_KEY,
		whitelist: ['isVisited'],
	},
	generalSlice.reducer
);
